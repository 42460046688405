import './NavMenu.css';

import Menu from '../../assets/Menu.svg';
import ProfileMenu from '../ProfileMenu';
import React from 'react';
import {
  useRecoilState,
} from 'recoil';
import { userDetails } from "../../Atom";
import woolpertLogo from '../../assets/Woolpert-Logo.svg';

const Strings = {};
Strings.orEmpty = function( entity ) {
    return entity || "";
};

const NavMenu = (props) => {
    const [userDetailsObj] = useRecoilState(userDetails);
    const profileMenuItems = {
        userName: Strings.orEmpty(userDetailsObj["name"]),
        organizationName: userDetailsObj["organization"],
        profileSrc: userDetailsObj["picture"],
        email: userDetailsObj["email"]
    }

    return (
        <div className="navMenu_wrapper">
            <div className="left_container">
                <img className="menu_icon" src={Menu} alt="menu_logo" />
                <img className="logo" src={woolpertLogo} alt="woolpert_logo" id="woolpertLogo"/>
                { userDetailsObj.authorization && userDetailsObj.authorization.isAuthorized &&
                    <div id="woolpertConnectTitle" className='header-title'>Woolpert Connect</div>
                }
            </div>
            { userDetailsObj.authorization && userDetailsObj.authorization.isAuthorized &&
                <div className="right_container">
                    <div id="userName" className="userName">{profileMenuItems?.userName}</div>
                    <ProfileMenu {...profileMenuItems}/>
                </div>
            }               
        </div>
    )
}

export default NavMenu;