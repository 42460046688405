export const sideDrawerItems = [
    {
        key: 'organization',
        value: 'Organization'
    },
    {
        key: 'subscriptions',
        value: 'Subscriptions'
    },
    {
        key: 'support',
        value: 'Support'
    }
]