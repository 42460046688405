import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import './Modal.css';


const CustomModal = (props) => {
    const { open, title, handleClose, children } = props;

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="license-cap"
            aria-describedby="license-cap-description"
        >
            <div className="modal_wrapper">
                <div className="modal_title">
                    {title}
                </div>
                <CloseIcon color="primary" className="modal_closeBtn" onClick={handleClose}/>
                <div className="modal_body">
                    {children}
                </div>
            </div>

        </Modal>
    )
}

export default CustomModal;