import React, { useState } from 'react';
import './Support.css';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '../../assets/Search.svg';

const SUPPORT_URL = process.env.REACT_APP_SUPPORT_URL;

export const Support = (props) => {

    const [searchField, setSearchField] = useState('');

    const handleChange = (event) => {
        const { value } = event.target;
        setSearchField(value);

    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            //points to search site with input value
            window.open(`${SUPPORT_URL}/hc/en-us/search?query=${searchField}`, '_blank')
        }
    }
    return (
        <React.Fragment>
            <div className="support_wrapper">
                <div className="support_link_container">
                    <div>
                        Woolpert Digital Innovations Support is available at <a href={SUPPORT_URL} target="_blank">
                            {SUPPORT_URL}
                        </a>
                        <div>This page provides quick access to the Woolpert Digital Innovations Support Portal.</div>
                    </div>
                    <div className="submit_request_link">
                        <a href={SUPPORT_URL + "/hc/en-us/requests/new"}
                            target="_blank" rel="noreferrer">
                            Submit a request
                        </a>
                    </div>
                </div>
            </div>
            <div className="support_search_wrapper">
                <div className="support_search_wrapper_input">
                    <Input
                        sx={{
                            background: 'white',
                            width: '430px',
                            borderRadius: '10px'
                        }}
                        value={searchField}
                        placeholder="Search"
                        startAdornment={<InputAdornment sx={{ marginLeft: '8px', marginRight: '10px' }}>
                            <img src={SearchIcon} alt="searchIcon" />
                        </InputAdornment>}
                        onChange={handleChange}
                        onKeyPress={handleKeyPress}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}
export default Support;