import React from 'react';
import './Footer.css';
import { getYear } from '../../utils';
import tos from '../../assets/woolpert_connect_ToS.pdf'

const Footer = (props) => {
    function openTermsOfService() {
        window.open(tos, '_blank',"noopener,noreferrer");
        
      }
    return (
        <div className="footer_wrapper">
            <div>
                <p className="footer_label">{`© ${getYear()} Woolpert Inc. All rights reserved.`}</p>
            </div>
            <div>
            <ul>
                    <button className="footer_label" rel="noopener,noreferrer" onClick={openTermsOfService}>Terms of Service</button>
                    <a className="footer_label" href="https://woolpert.com/privacy-policy/" target="_blank">Privacy Policy</a>
                </ul>
            </div>
        </div>
    )
}

export default Footer;