import './Organization.css';

import React, { useEffect, useState } from 'react';
import { useRecoilCallback, useRecoilState } from 'recoil';

import ServerErrorMessage from '../ServerError'
import axios from 'axios';
import { userDetails } from "../../Atom";

const API_URL = process.env.REACT_APP_API_URL;

const Organization = () => {
    const emptryOrgRes = {
        organizationName: "",
        customerDomain: "",
        customerType: "",
        customerId: "",
        addressLine1: "",
        locality: "",
        region: "",
        postalCode: "",
        countryCode: "",
        languageCode: "",
        primaryContact : {
            givenName: "",
            familyName: "",
            primaryEmail: "",
            alternateEmail: "",
            recoveryPhone: ""
        }
    }

    const [userDetailsObj] = useRecoilState(userDetails);

    const [details, setDetails] = useState(emptryOrgRes);
    const [isLoading,setIsLoading] = useState(true);

    const [serverErrorMessage, setServerErrorMessage] = useState(false)

    const fetchApiDetails = async () => {
        setIsLoading(true);
        await axios
            .get(API_URL + '/organizations/' + userDetailsObj.domainName, {
                headers: {
                    'Authorization': `Bearer ${userDetailsObj.token}`
                }
            })
            .then(resp => {
                setDetails(resp?.data);
                // console.log("Get Organization response: ", resp?.data)
                setIsLoading(false)
            })
            .catch(error => {
                console.log('Error::', error)

                setServerErrorMessage(true)
                setIsLoading(false);
            })
    }

    useEffect(() => {
        fetchApiDetails();
    }, [])

    const updateOrgName = useRecoilCallback(({ set }) => (value) => {
        set(userDetails, value);
      });

    useEffect(() => {
        if (details) {
            var tempState = JSON.parse(JSON.stringify(userDetailsObj));
            tempState.organizationName = details.organizationName;
            updateOrgName(tempState);
        }
    },[details])

    const renderOrganizationInformation = () => {

        const { organizationName, customerDomain, customerType, customerId,
            addressLine1, locality, region, postalCode, countryCode, languageCode } = details
        return (
            <div className="wrapper">
                <div className="orgTitle">Organization Information</div>
                <div className="details_wrapper">
                    <div className="organization_content_wrapper">
                        <div className="organization_content_title">Organization Name:</div>
                        <div className="organization_content_value">{organizationName || '-'}</div>
                    </div>
                    <div className="organization_content_wrapper">
                        <div className="organization_content_title">Domain:</div>
                        <div className="organization_content_value">{customerDomain || '-'}</div>
                    </div>
                    <div className="organization_content_wrapper">
                        <div className="organization_content_title">Type:</div>
                        <div className="organization_content_value">{customerType || '-'}</div>
                    </div>
                    <div className="organization_content_wrapper">
                        <div className="organization_content_title">Cloud Identity ID:</div>
                        <div className="organization_content_value">{customerId || '-'}</div>
                    </div>
                    <div className="organization_content_wrapper">
                        <div className="organization_content_title">Street Address:</div>
                        <div className="organization_content_value">{addressLine1 || '-'}</div>
                    </div>
                    <div className="organization_content_wrapper">
                        <div className="organization_content_title">City:</div>
                        <div className="organization_content_value">{locality || '-'}</div>
                    </div>
                    <div className="organization_content_wrapper">
                        <div className="organization_content_title">State:</div>
                        <div className="organization_content_value">{region || '-'}</div>
                    </div>
                    <div className="organization_content_wrapper">
                        <div className="organization_content_title">Zip Code:</div>
                        <div className="organization_content_value">{postalCode || '-'}</div>
                    </div>
                    <div className="organization_content_wrapper">
                        <div className="organization_content_title">Country / Region:</div>
                        <div className="organization_content_value"> {countryCode || '-'}</div>
                    </div>
                    <div className="organization_content_wrapper">
                        <div className="organization_content_title">Language:</div>
                        <div className="organization_content_value">{languageCode || '-'}</div>
                    </div>
                </div>
            </div>
        )
    }

    const renderPrimaryContactInformation = () => {
        const { primaryContact } = details;
        const { givenName, familyName, primaryEmail, alternateEmail, recoveryPhone } = primaryContact
        return (
            <div className="wrapper">
                <div className="orgTitle">Primary Contact Information</div>
                <div className="details_wrapper">
                    <div className="organization_content_wrapper">
                        <div className="organization_content_title">First Name:</div>
                        <div className="organization_content_value">{givenName || '-'}</div>
                    </div>
                    <div className="organization_content_wrapper">
                        <div className="organization_content_title">Last Name:</div>
                        <div className="organization_content_value">{familyName || '-'}</div>
                    </div>
                    <div className="organization_content_wrapper">
                        <div className="organization_content_title">Email:</div>
                        <div className="organization_content_value">{primaryEmail || '-'}</div>
                    </div>
                    <div className="organization_content_wrapper">
                        <div className="organization_content_title">Alternate Email:</div>
                        <div className="organization_content_value">{alternateEmail || '-'}</div>
                    </div>
                    <div className="organization_content_wrapper">
                        <div className="organization_content_title">Phone:</div>
                        <div className="organization_content_value">{recoveryPhone || '-'}</div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <React.Fragment>
            {isLoading ? <h2 className='organization_wrapper'>Loading ...</h2> : (
                <div className="information_wrapper">
                    {serverErrorMessage ? <ServerErrorMessage serverErrorBoolean={serverErrorMessage}/> : 
                    <>
                        {renderOrganizationInformation()}
                        {renderPrimaryContactInformation()}
                    </>
                }
                </div>
            )}
        </React.Fragment>
    )
}

export default Organization;
