import './App.css';

import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import React, { useEffect } from 'react';
import {
  useRecoilState,
  useSetRecoilState
} from 'recoil';

import Footer from './components/Footer';
import { GoogleOAuthProvider } from "@react-oauth/google";
import Login from './components/Login';
import NavMenu from './components/NavMenu';
import PageView from './components/PageView';
import { userDetails } from "./Atom";
console.log("Woolpert Connect Version: v0.1.2")
const clientId = process.env.REACT_APP_CLIENT_ID;

export const App = () => {
  const setUserDetails = useSetRecoilState(userDetails);

  const checkSetLocalStorage = () => {
    // Check for local storage
    const localStorageObj = JSON.parse(localStorage.getItem('userDetails'));

    // Update state management with local storage obj
    if (localStorageObj && localStorageObj.authorization.isAuthorized) {
      setUserDetails(localStorageObj);
      // TODO manage case when user is logged in and refreshes the page
    }
  }

  const HomePage = () => {
    return (
      <>
        <NavMenu />
        <PageView />
        <Footer />
      </>
    );
  }

  const OtherPaths = () => {
    // Confine the user to two paths: login and home
    const userDetailsObj = useRecoilState(userDetails);
    let location = useLocation();

    if (userDetailsObj[0]["authorization"]["isLogin"]) {
      return <Navigate to="/organization" state={{ from: location }} replace />;
    } else {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
  }

  const RequireAuth = ({ children }) => {
    // Send any unauthenticated user to the login page
    const userDetailsObj = useRecoilState(userDetails);
    let location = useLocation();

    if (!userDetailsObj[0]["authorization"]["isLogin"]) {
      // Redirect user to the /login page
      return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
  }

  useEffect(() => {
    checkSetLocalStorage();
  }, []);

  return (
    <GoogleOAuthProvider clientId={clientId}>
      <Routes>
        <Route path="/login" element={<Login />} /> {/* Login page */}
        <Route path="/*" element={<OtherPaths />} /> {/* Page re-route */}
        <Route
            path="/organization"
            element={
              <RequireAuth>
                <HomePage /> {/* Protected page(s) */}
              </RequireAuth>
            }
          />
        <Route
            path="/subscriptions"
            element={
              <RequireAuth>
                <HomePage /> {/* Protected page(s) */}
              </RequireAuth>
            }
          />
          <Route
            path="/support"
            element={
              <RequireAuth>
                <HomePage /> {/* Protected page(s) */}
              </RequireAuth>
            }
          />
      </Routes>
    </GoogleOAuthProvider>
  );
};

export default App;
