import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from 'recoil';
import './fonts/Avenir/Avenir-LT-95-Black.ttf';
import './fonts/MyriadPro/MYRIADPRO-REGULAR.OTF';

ReactDOM.render(
  <RecoilRoot>
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </RecoilRoot>,
  document.getElementById('root')
);
