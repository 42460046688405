import React from 'react';
import TextField from '@mui/material/TextField';

const runningTests = (process.env.REACT_APP_CYPRESS_TESTS === "true")

const InputField = (props) => {
    const { label, variant, colorVariant, type, handleChange, value, isDisabled, maxSeats, minSeats } = props;
    return (
        <>
            <TextField
                InputProps={{
                    inputProps: {
                        min: minSeats,
                        max: maxSeats
                    }
                }}
                sx={{width: 150}}
                label={label}
                variant={variant}
                color={colorVariant}
                type={type}
                value={value}
                disabled={isDisabled}
                onChange={handleChange}
                onKeyDown={(event) => {
                    if (!runningTests) {
                        event.preventDefault();
                    }
                }}
            />
        </>
    )
}

export default InputField;