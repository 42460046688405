import './ProfileMenu.css'

import { useRecoilState, useSetRecoilState } from 'recoil';

import Menu from '@mui/material/Menu';
import React from 'react';
import { useNavigate } from "react-router-dom";
import { userDetails } from "../../Atom";

const ProfileMenu = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [userDetailsObj] = useRecoilState(userDetails)
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event) => {
      setAnchorEl(event.currentTarget)
  }
  const setUserDetails = useSetRecoilState(userDetails);

  const handleSignOut = () => {
    // Clear the local storage
    //set atom back to default values
    localStorage.clear();
    const userDetailsDefault = {
      authorization: {
        isLogin: false,
        isAuthorized: false },
      domainName: "",
      email: "",
      name: "",
      picture: "",
      organizationName: "",
      token: ""
    }
    setUserDetails(userDetailsDefault);

    // Redirect the user back to the login page
    navigate("/login");
  }

  const handleClose = () => {
      setAnchorEl(null)
  }

  return (
    <>
      <img
        className='navMenuImage'
        src={userDetailsObj["picture"]}
        alt="profile_image"
        onClick={handleClick}
        style={{cursor: 'pointer'}}
        id="profileMenuIcon"
      />
      <Menu
        id="profile-menu"
        sx={{
            '& .MuiMenu-list': {
              padding: 0
            }
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}

        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        MenuListProps={{
          'aria-labelledby': 'profile-menu-items',
        }}
      >
      <div className="profile_wrapper">
          <div className="profile_header">{userDetailsObj["organizationName"]}</div>
          <div className="profile_image">
              <img src={userDetailsObj["picture"]} alt="profile_image" />
          </div>
          <div className="profile_footer">
              <p>{userDetailsObj["email"]}</p>
              <p
                onClick={handleSignOut}
              >
                Sign Out
              </p>
          </div>
        </div>
      </Menu>
    </>
  )
}

export default ProfileMenu;
