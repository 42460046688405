import './Subscription.css';

import { Box, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { calculateMax, calculateMin, convertDate } from './utils';

import { InputField } from '../Form';
import Modal from '../Modal';
import ServerErrorMessage from '../ServerError'
import axios from 'axios';
import cloud_identity from '../../assets/cloud_identity_64dp.png';
import google_drive from '../../assets/google_drive_64dp.png';
import google_vault from '../../assets/google_vault_64dp.png';
import google_voice from '../../assets/google_voice_64dp.png';
import google_workspace from '../../assets/GoogleWorkspace.svg';
import { useRecoilState } from 'recoil';
import { userDetails } from "../../Atom";

const API_URL = process.env.REACT_APP_API_URL;
const SUPPORT_URL = process.env.REACT_APP_SUPPORT_URL;

export const Subscription = (props) => {
    // Set the state for the Modal
    const [userDetailsObj] = useRecoilState(userDetails);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [subscriptionList, setSubscriptionList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [serverErrorMessage, setServerErrorMessage] = useState(false)
    const [subscriptionServerErrorMessage, setSubscriptionServerErrorMessage] = useState(false)


    //add additional allowed Skus to this lsit as they become available
    const allowedSkus = ["Google Workspace Business Starter",
     "Google Workspace Business Standard",
      "Google Workspace Business Plus",
       "Google Workspace Enterprise Standard",
        "Google Workspace Enterprise Plus",
         "Google Workspace Enterprise Essentials",
          "Cloud Identity Premium",
          "Google Workspace Business Starter - Archived User",
          "Google Workspace Business Standard - Archived User",
           "Google Workspace Business Plus - Archived User",
            "Google Workspace Enterprise Standard - Archived User",
             "Google Workspace Enterprise Plus - Archived User",
              "Google Workspace Enterprise Essentials - Archived User",
               "Cloud Identity Premium - Archived User"]
    const allowedPlans= ["Flexible", "Annual - Monthly"]


    const [activeSubscription, setActiveSubscription] = useState({
        serviceName: "",
        licenseCapValue: "",
        origLicenseCapValue: "",
        planName: "",
        selectionIndex: "",
        minSeats: "",
        maxSeats: "",
        subscriptionId: "",
        maximumSeats: "",
        status: ""
    });

    const changeSubscriptionCapacity = async (patchBody) => {
        setActivePage(3);
        // console.log('Patchbody 2::', patchBody)

        await axios
            .patch(
                API_URL +
                '/organizations/' +
                userDetailsObj.domainName +
                '/subscription/' +
                activeSubscription.subscriptionId, patchBody, {
                    headers: {
                        'Authorization': `Bearer ${userDetailsObj.token}`
                    }
                }
            )

            .then(res => {
                // Update the active subscription
                setActiveSubscription(prevState => ({
                    ...prevState,
                    licenseCapValue: res.data.maximumSeats,
                    minSeats: res.data.availableSeats,
                    maximumSeats: res.data.maximumSeats
                }));

                // Update the subscription list
                let copySubscriptionList = JSON.parse(JSON.stringify(subscriptionList));
                copySubscriptionList[activeSubscription.selectionIndex] = res.data;
                setSubscriptionList(copySubscriptionList);

                // Close the modal and reset the modal to page 1
                setIsModalOpen(false);
                setActivePage(1);
            })
            .catch(error => {
                console.log('Error::', error)

                setSubscriptionServerErrorMessage(true);
            })
    }

    const subscriptionIcons = {
        'Cloud Identity': cloud_identity,
        'Google Drive': google_drive,
        'Google Vault': google_vault,
        'Google Voice': google_voice,
        'Google Workspace': google_workspace
    };

    const fetchSubscriptionDetails = async () => {
        setIsLoading(true);
        await axios
            .get(API_URL + '/organizations/' + userDetailsObj.domainName + '/subscriptions', {
                headers: {
                    'Authorization': `Bearer ${userDetailsObj.token}`
                }
            })
            .then(res => {
                setSubscriptionList(res.data);
                setIsLoading(false);
            })
            .catch(error => {
                // If the above API fails in error
                console.log('Error::', error)
                setServerErrorMessage(true);
                setIsLoading(false);
            })
    }


    const handleButtonClick = (index) => (e) => {
        setActiveSubscription(prevState => ({
            licenseCapValue: subscriptionList[index].maximumSeats,
            origLicenseCapValue: subscriptionList[index].licensedNumberOfSeats,
            serviceName: subscriptionList[index].skuName,
            planName: subscriptionList[index].planName,
            selectionIndex: index,
            minSeats: calculateMin(subscriptionList[index]),
            maxSeats: calculateMax(subscriptionList[index]),
            subscriptionId: subscriptionList[index].subscriptionId,
            maximumSeats: subscriptionList[index].maximumSeats,
            status: subscriptionList[index].status
        }));

        setIsModalOpen(true);
        // console.log(activeSubscription);
    }

    const handleModalClose = () => {
        setIsModalOpen(false);
        setActivePage(1);
        setActiveSubscription(prevState => ({
            ...prevState,
            licenseCapValue: ''
        }));
        setSubscriptionServerErrorMessage(false)
    }

    const handleChange = (e) => {
        const { value } = e.target;
        setActiveSubscription(prevState => ({
            ...prevState,
            licenseCapValue: Number(value)
        }));
    }

    const handleCancelClick = () => {
        if (activePage === 1) {
            setIsModalOpen(false);
            setActiveSubscription(prevState => ({
                ...prevState,
                licenseCapValue: ''

            }));
        }
        else setActivePage(1);

        setSubscriptionServerErrorMessage(false)
    }

    const SubscriptionImage = (subscriptionName) => {
        let image;
        for (let key in subscriptionIcons) {
            if (subscriptionName.startsWith(key)) {
                image = subscriptionIcons[key];
            }
        }
        return (
            <img className='icon_image' src={image} alt="logo" />
        )
    }

    const handleConfirmClick = () => {
        /*
        **************
        Note:
            There are 2 plans:
                Flexible - the user is able to modify the maximumNumberOfSeats from 1 (min) or the currently
                    used licenses (whichever is bigger) to 300 (max)
                Annual - monthly - the user is able to only increase the numberOfSeats, with a max of 50,000
        **************
        */
        if (activePage === 1) setActivePage(2)
        else {
            if (activeSubscription.planName === 'Annual - Monthly'){
                const patchBody = {
                    "kind": "subscriptions#seats",
                    "numberOfSeats": activeSubscription.licenseCapValue,
                    "userKey": userDetailsObj.email

                };
                   // handle the API call for License Cap Change.
                changeSubscriptionCapacity(patchBody);
            }
            else if(activeSubscription.planName === 'Flexible'){
                const patchBody = {
                    "kind": "subscriptions#seats",
                    "maximumNumberOfSeats": activeSubscription.licenseCapValue,
                    "userKey": userDetailsObj.email

                };
                   // handle the API call for License Cap Change.
                // console.log('Patchbody 1::', patchBody)

                changeSubscriptionCapacity(patchBody);
            }
        }
    }

    const renderButton = (index) => {
        return (
            <button
                className="btn_wrapper"
                onClick={handleButtonClick(index)}>
                Change
            </button>
        )
    }

    useEffect(() => {
        fetchSubscriptionDetails()
    }, [])

    //method to check that there are subscriptions to be rendered
    const renderServiceDetails = () => {
        if(subscriptionList.length > 0){
            // console.log("len of subscriptions",subscriptionList.length)
            return (renderSubscriptions());
        }
        else{
            return (noSubscriptionsMessage());
        }
    }

    //when there are no subscriptions to be displayed an error message is shown instead
    const noSubscriptionsMessage = () => {
        return (
            <>
                <div className='noSubscriptionsMessage' id="noSubscriptionsID">
                <div className="noSubscriptionsMessageText">
                    <div> <strong>No subscriptions to display  </strong></div>
                    <br></br>
                    <span> Please contact Woolpert Digital Innovations support if you believe this is an error</span>
                    <div className="submit_request_link">
                        <a href={SUPPORT_URL + "/hc/en-us/requests/new"}
                            target="_blank" rel="noreferrer">
                            <br></br>
                            Submit a request
                        </a>
                    </div>
                </div>
                </div>
            </>
        )
    }
    //normal subscription information rendering
    const renderSubscriptions = () => {
        return (
            <div className="subscription_display">
                {subscriptionList.map((subscription, index) => (
                    <div className="wrapper contact_wrapper">
                        <div className="subTitle">
                            {SubscriptionImage(subscription.skuName)}
                            {subscription?.skuName}
                        </div>
                        <div className="details_wrapper">
                        <div className="subscription_content_wrapper">
                            <div className="subscription_content_title">Subscription ID:</div>
                            <div className="subscription_content_value">{subscription?.subscriptionId || '-'}</div>
                        </div>
                        <div className="subscription_content_wrapper">
                            <div className="subscription_content_title">Status:</div>
                            <div className="subscription_content_value">{subscription?.status || '-'}</div>
                        </div>
                        <div className="subscription_content_wrapper">
                            <div className="subscription_content_title">Created on:</div>
                            <div className="subscription_content_value">{convertDate(subscription.creationTime) || '-'}</div>
                        </div>
                        <div className="subscription_content_wrapper">
                            <div className="subscription_content_title">Payment plan:</div>
                            <div className="subscription_content_value">{subscription?.planName || '-'}</div>
                        </div>
                        <div className="subscription_content_wrapper">
                            <div style={{ color: '#0c69ff', fontWeight: '400' }} className="subscription_content_title">Licenses:</div>
                            <div> {renderButton(index)}</div>
                        </div>
                        <div className="subscription_content_wrapper">
                            <div className="subscription_content_title">Maximum:</div>
                            {(subscription?.maximumSeats || subscription?.maximumSeats === 0) ?
                                <div className="subscription_content_value" id="maximumSeatsValue">{subscription?.maximumSeats}</div> :
                                <div className="subscription_content_value">-</div>
                            }
                        </div>
                        <div className="subscription_content_wrapper">
                            <div className="subscription_content_title">Used:</div>
                            {(subscription?.licensedNumberOfSeats|| subscription?.licensedNumberOfSeats === 0) ?
                                <div className="subscription_content_value">{subscription?.licensedNumberOfSeats}</div> :
                                <div className="subscription_content_value">-</div>
                            }
                        </div>
                        <div className="subscription_content_wrapper">
                            <div className="subscription_content_title">Available:</div>
                            <div className="subscription_content_value" id="availableSeatsValue">{subscription?.availableSeats}</div>
                        </div>
                    </div>
                    </div>
                ))
                }
            </div>
        )
    }

    const renderInputField = () => {
        return (
            <InputField
                label="License cap*"
                variant="outlined"
                colorVariant="primary"
                type="number"
                value={activeSubscription.licenseCapValue}
                isDisabled={false}
                handleChange={handleChange}
                maxSeats={activeSubscription.maxSeats}
                minSeats={activeSubscription.minSeats}
            />
        )
    }

    const renderPageView = () => {
        switch (activePage) {
            case 1:
                return <>
                    <div>
                        {`The license cap for this Subscription of ${activeSubscription.serviceName} is currently ${"" + activeSubscription.maximumSeats}. Please enter the new license cap you would like to change the subscription to, a maximum of 10 new seats can be added per update.`}
                    </div>
                    <br></br>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                        {renderInputField()}
                    </Box>
                </>
            case 2:
                return <div>
                    {`The License cap for this subscription of ${activeSubscription.serviceName} will be updated from ${"" + activeSubscription.maximumSeats} to ${"" + activeSubscription.licenseCapValue}. Would you like to proceed with the update?`}
                </div>
            case 3:
                return(
                    subscriptionServerErrorMessage ? <ServerErrorMessage serverErrorBoolean={subscriptionServerErrorMessage}/> :
                    <>
                        <div className='loadingErrorMessage'>
                        <div className="loadingErrorMessageText">
                            <div>Loading ...  </div>
                        </div>
                        </div>
                    </>
                )

            default:
                return null
        }
    }

    const renderForbiddenModalBody = () => {
        return (
            <>
                <span>Please contact Woolpert support if you wish to add seats to the plan.</span>
                <div className="submit_request_link">
                    <a href={SUPPORT_URL + "/hc/en-us/requests/new"}
                        target="_blank" rel="noreferrer">
                        <br></br>
                        Submit a request
                    </a>
                </div>
            </>
        )
    }

    const renderMutableModalBody = () => {
        return (
            <div className="modal_description">
                {renderPageView()}

                <div className="modal_footer">
                {/* <div>
                    Do you want to continue with the update?
                </div> */}
                    <Button
                        sx={{
                            fontWeight: '700',
                            textTransform: 'capitalize',
                            mr: '1rem'
                        }}
                        variant="text"
                        onClick={handleCancelClick}>
                        {activePage === 1 ? 'Cancel' : "Back"}
                    </Button>

                    <Button
                        id='continueConfirmBtn'
                        sx={{
                            textTransform: 'capitalize',
                            background: '#0c69ff'
                        }}
                        variant='contained'

                        disabled={(subscriptionServerErrorMessage || (activeSubscription.maximumSeats === activeSubscription.licenseCapValue)|| (activeSubscription.licenseCapValue-activeSubscription.maximumSeats > 10)) ? true : false}
                        onClick={handleConfirmClick}>
                        {activePage === 1 ? 'Continue' : 'Confirm'}
                    </Button>
                </div>
            </div>
        )
    }
// origLicenseCapValue
    return (
        <>
            <div className="subscription_wrapper">
                {isLoading ? <h2>Loading ...</h2> : serverErrorMessage ? <ServerErrorMessage serverErrorBoolean={serverErrorMessage}/> : renderServiceDetails()}
            </div>
            {/* Allowed subscriptions to be changed:
                    Google Workspace Business or Enterprise editions: Flex
                    Google Workspace Business or Enterprise editions: Annual - Monthly
                    Cloud Identity Premium " */}
            <Modal open={isModalOpen} handleClose={handleModalClose} title=
                {allowedSkus.includes(activeSubscription.serviceName) && (allowedPlans.includes(activeSubscription.planName) || activeSubscription.serviceName === "Cloud Identity Premium") && activeSubscription.status === "ACTIVE" ?
                 `Change License Cap for ${activeSubscription.serviceName}` :
                  "License cap cannot be changed for this subscription"}>

                {allowedSkus.includes(activeSubscription.serviceName) && (allowedPlans.includes(activeSubscription.planName) || activeSubscription.serviceName === "Cloud Identity Premium") && activeSubscription.status === "ACTIVE" ?
                 renderMutableModalBody():
                 renderForbiddenModalBody()}
            </Modal>
        </>
    )
}

export default Subscription;
