import React from 'react';
import './SideDrawer.css'

const SideDrawer = (props) => {
    const { options, handleItemClick, activeItem } = props;

    return (
        <div className="sideDrawer">
            <div className="navItems_wrapper">
                {options.map(item => (
                    <div
                        key={item.key}
                        className={`navItems_label ${activeItem === item.key && 'isActive'}`}
                        onClick={() => handleItemClick(item.key)}>
                        {item.value}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default SideDrawer;