import React from 'react';

//error for when there is a domain error in the API call
export default function ServerErrorMessage(props) {
    if (props.serverErrorBoolean) {
        return (
        <>
            <div className='domainError'>
            <div className="domainErrorText">
                <div> A server error occurred </div>
            </div>
            </div>
        </>
        )
    } else {
        return <></>
    }
}
