import { atom } from "recoil";
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist()

export const userDetails = atom({
  key: "userDetails",
  default: {
    authorization: {
      isLogin: false,
      isAuthorized: false
    },
    domainName: "",
    email: "",
    name: "",
    picture: "",
    organizationName: "",
    token: "",
  },
  effects_UNSTABLE: [persistAtom]
});
