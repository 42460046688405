export const calculateMin = (subscription) => {
    if (subscription.planName === 'Flexible'){
        if (subscription.licensedNumberOfSeats > 1){
            return(subscription.licensedNumberOfSeats)
        }
        else {
            return(1)
        }
    }
    else if(subscription.planName === 'Annual - Monthly'){
        return(subscription.maximumSeats + 1)
    }
    else{
        return(1)
    }
}

export const calculateMax = (subscription) => {
    if (subscription.planName === 'Flexible'){
        if(subscription.maximumSeats > 290){
            return(300)
        }
        else{
            return subscription.maximumSeats + 10
        }
    }
    else if(subscription.planName === 'Annual - Monthly'){
        if(subscription.maximumSeats > 49990){
            return(50000)
        }
        else{
            return subscription.maximumSeats + 10
        }
    }
}

export const convertDate = (creationTime) => {
    const creationTimeInt = parseInt(creationTime);
    const date = new Date(creationTimeInt);
    const createdDate= date.toLocaleDateString();

    return createdDate
}