import React, { useEffect, useState } from 'react';
import Organization from '../Organization';
import SideDrawer from '../SideDrawer';
import Subscription from '../Subscription';
import Support from '../Support';
import { sideDrawerItems } from './utils';

const PageView = () => {
    const [selectedValue, setSelectedValue] = useState(sideDrawerItems[0].key) // account intital value 

    const rightViewComponent = {
        'organization': <Organization />,
        'subscriptions': <Subscription />,
        'support': <Support />
    }

    useEffect(() => {
        // On page refresh the previous URL path is set in app.js/checkSetLocalStorage.
        // Then the current tab is modified here.
        const currentPath = window.location.pathname.slice(1);
        setSelectedValue(currentPath);
    },[])

    const handleClick = (value) => {
        window.history.replaceState("", "", value);
        window.history.pushState("", value);
        setSelectedValue(value);
    }

    return (
        <React.Fragment>
            <SideDrawer options={sideDrawerItems} handleItemClick={handleClick} activeItem={selectedValue} />
            {rightViewComponent[selectedValue]}
        </React.Fragment>
    )
}

export default PageView;