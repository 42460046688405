import './Login.css';

import React, { useEffect, useState } from 'react';
import { useRecoilCallback, useRecoilState, useSetRecoilState } from 'recoil';

import Footer from '../Footer';
import { GoogleLogin } from '@react-oauth/google';
import NavMenu from '../NavMenu';
import ServerErrorMessage from '../ServerError'
import axios from 'axios';
import jwtDecode from "jwt-decode";
import loginImage from '../../assets/ConnectGraphic.svg';
import { useNavigate } from "react-router-dom";
import { userDetails } from "../../Atom";

const API_URL = process.env.REACT_APP_API_URL;

export default function Login() {
  const navigate = useNavigate();

  const setUserDetails = useSetRecoilState(userDetails);
  const [userDetailsObj] = useRecoilState(userDetails);

  const [userDetailsObjState, setUserDetailsObjState] = useState();
  const [isUserWhitelisted, setIsUserWhitelisted] = useState(false);
  const [domainErrorMessage, setDomainErrorMessage] = useState(false);
  const [superAdminErrorMessage, setSuperAdminErrorMessage] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState(false)
  const [showLoadingMessage, setShowLoadingMessage] = useState(false);
  const [credential, setCredential] = useState('');


  const triggerLogoutTimer = () => {
    localStorage.clear();
    const userDetailsDefault = {
      authorization: {
        isLogin: false,
        isAuthorized: false },
      domainName: "",
      email: "",
      name: "",
      picture: "",
      organizationName: ""
    }

    setUserDetails(userDetailsDefault);
  }

  /* STEP 1 Google login */
  const googleLoginSuccess = (credentialResponse) => {
    // Note the token will expire after an hour, so after an hour we will log the user out
    // If the token expiration should change for some reason, we may want to alter this workflow.
    setTimeout(triggerLogoutTimer, 1000 * 3600)

    setDomainErrorMessage(false);
    setSuperAdminErrorMessage(false);
    setShowLoadingMessage(true);
    setCredential(credentialResponse.credential)

    const hostData = jwtDecode(credentialResponse.credential);
    const userDetailsRes = {
      authorization: {
        isLogin: true,
        isAuthorized: false
      },
      domainName: hostData.hd,
      email: hostData.email,
      name: hostData.name,
      picture: hostData.picture,
      organizationName: '',
      token: credentialResponse.credential
    }
    setUserDetails(userDetailsRes);
    setUserDetailsObjState(userDetailsRes);
  }

  /* STEP 2 Check of the user is whitelisted */
  const checkUserWhitelisted = async () => {
    setIsUserWhitelisted(false);

    await axios
      .get(API_URL + '/authorization', {
        headers: {
          "Authorization": "Bearer " + credential
        }
      })
      .then(resp => {
        setIsUserWhitelisted(resp.data);
      })
      .catch(error => {
        if (error.request.status == 401) {
          setShowLoadingMessage(false);
          setSuperAdminErrorMessage(true);
        } else {
          setShowLoadingMessage(false);
          setServerErrorMessage(true);
        }
      })
    }

  /* STEP 4 Set isAuthorized in Atom state in order to validate the authorization of the user */
  const updateAuthorizationState = useRecoilCallback(({ set }) => (value) => {
    set(userDetails, value);
  });

  useEffect(() => {
    //  The user is allowed to access the app when they are whitelisted
    if (isUserWhitelisted) {
      setShowLoadingMessage(false);
      navigate("/organization");
      let tempState = JSON.parse(JSON.stringify(userDetailsObjState));
      tempState.authorization.isAuthorized = true;
      updateAuthorizationState(tempState);
    }
  },[isUserWhitelisted])

  useEffect(() => {
    // When the user is logged in trigger step 2 (check domain authorization)
    if (userDetailsObjState && userDetailsObjState.authorization.isLogin) {
      checkUserWhitelisted();
    }
  },[userDetailsObjState])


  const DomainErrorMessage = () => {
    // Conditional logic for when user's domain is not recognized
    if (domainErrorMessage) {
      return (
        <>
          <div className='domainError'>
            <div className="domainErrorText">
              <div>The domain {userDetailsObj.organizationName} is not managed by Woolpert. </div>
              <br></br>
              <div>Please contact customersuccess@woolpert.com for assistance. </div>
            </div>
          </div>
        </>
      )
    } else {
      return <></>
    }
  }

  const SuperAdminUserErrorMessage = () => {
    // Conditional logic for when user is not super admin
    if (superAdminErrorMessage) {
      return (
        <>
        <div className='domainError'>
          <div className="domainErrorText">
            <div>The account {userDetailsObj.email} does not have the super admin role.  </div>
            <br></br>
            <div>Please contact your domain administrator for assistance. </div>
          </div>
        </div>
      </>
      )
    }
    else {
      return <></>
    }
  }

  const LoadingMessage = () => {
    if (showLoadingMessage) {
      return (
        <>
        <div className='loadingErrorMessage'>
          <div className="loadingErrorMessageText">
            <div>Loading ...  </div>
          </div>
        </div>
      </>
      )
    }
    else {
      return <></>
    }
  }

  return (
    <>
      <NavMenu/>
      <div className='container'>

        <div className="line-break"></div>
        <div className='googleLogin'>
          <GoogleLogin
            id="googleLogin"
            theme="filled_blue"
            logo_alignment="left"
            size="large"
            onSuccess={credentialResponse => {
              googleLoginSuccess(credentialResponse)
            }}
            onError={() => {
                console.log('Login Failed');
            }}
          />
        </div>
        <div className="line-break"></div>
        <LoadingMessage/>
        <DomainErrorMessage/>
        <SuperAdminUserErrorMessage/>
        <ServerErrorMessage serverErrorBoolean={serverErrorMessage}/>
        <div className="line-break"></div>

        {/* <div className='bottomImage'>
          <img src ={globe} alt= "globe_image" />
        </div> */}
        <div className='bottomImage'>
          <img src ={loginImage} object-fit = 'cover' alt= "login_image" />
        </div>
      </div>
      <Footer />
    </>
  )
}
